<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <router-view></router-view>
      </div>
    </div>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  import footerNav from "@/components/Footer";
  export default {
    components: {
      headNav,
      sideBar,
      footerNav
    },
    data (){
      return {
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  .wrap{
    width: 100%;
    .con-wrap{
      display: flex;
      justify-content: space-between;
      width: inherit;
      background-color: #F5F5F5;
      .pages-con-wrap{
        width: 84.8958%;
        background-color: #fff;
      }
    }
  }
</style>
